import './app.scss';
import React, { useState } from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import './App.css';
import Home from "./components/home";
import Job from "./components/job";
import Employers from "./components/employers";
import EmployersPremium from "./components/employersPremium";
import EmployersOpenToHire from "./components/employersOpenToHire"
import SalaryGuide from './components/salaryGuide';
import HuntingLanging from './components/huntingLanding';


import LocaleContext from "./components/localeContext";
import * as country from "./common/country";
import Header from "./components/header";
import Footer from "./components/Footer"

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function App() {
  let query = useQuery();
  if (query.has("site")) {
    let countryCode = query.get("site")
    if (!country.codes.includes(countryCode)) countryCode = 'CL';
    window.localStorage.setItem('country-code', countryCode)
  }

  const [countryCode, _setCountryCode] = useState(window.localStorage.getItem('country-code'));
  const [showSwitch, setShowSwitch] = useState(true);
  const setCountryCode = (countryCode) => {
    if (!country.codes.includes(countryCode)) countryCode = 'CL';
    window.localStorage.setItem('country-code', countryCode);
    _setCountryCode(countryCode);
  }

  return (
    <LocaleContext.Provider value={{ countryCode, setCountryCode, showSwitch, setShowSwitch }}>
      <Header />
      <Switch>
        <Route path="/show-me-the-money">
          <SalaryGuide />
        </Route>
        <Route path="/postulacion-y-carrera">
          <EmployersOpenToHire siteParam={query.get('site')} />
        </Route>
        <Route path="/empleadores">
          <Employers />
        </Route>
        <Route path="/empleadores-premium">
          <EmployersPremium />
        </Route>
        <Route path="/hunting-empresas">
          <HuntingLanging employerType="company" />
        </Route>
        <Route path="/hunting">
          <HuntingLanging employerType="law-firm" />
        </Route>
        <Route path="/:id" children={<Job />} />
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </LocaleContext.Provider>
  );
}

export default App;

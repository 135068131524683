import Card from 'react-bootstrap/Card';

const BlogItem = ({ date, title, link, photo }) => {
  return (
    <Card className='.d-flex h-120'>
      <Card.Img
        variant="top"
        src={photo}
        style={{ "aspectRatio": "98/65" }}
      />
      <Card.Body>
        <Card.Title>{date}</Card.Title>
        <Card.Text>
          {title}
        </Card.Text>
        <a
          className="btn btn-dark text-uppercase"
          rel="noreferrer"
          target="_blank"
          href={link}
        >
          Leer más
        </a>
      </Card.Body>
    </Card>
  );
}

export default BlogItem;
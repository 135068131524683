import React, { useContext } from 'react';
import { withRouter } from "react-router";
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import wolData from '../common/wolData';
import * as country from '../common/country'
import LocaleContext from "./localeContext";
import ReactCountryFlag from "react-country-flag";
import logo from "../images/logotipo.svg"

const Header = ({ location }) => {
  const { countryCode, setCountryCode, showSwitch } = useContext(LocaleContext);
  //const navbarClass = (location.pathname === "/") ? "homepage-navbar" : "old-navbar";
  const navbarClass = "homepage-navbar";


  return (
    <Navbar id="wol-navbar" expand="lg">
      {navbarClass === "old-navbar" ? ( //Unused logic
        <Navbar.Brand id="wol-navbar-brand" href={wolData.urls.homepageUrl}>
          <span>Work On</span>
          <span style={{ color: '#960F0F' }}> Law</span>
        </Navbar.Brand>
      ) : (
        <div>
          <a className="navbar-brand" href={wolData.urls.homepageUrl}>
            <img src={logo} alt='Work on law' />
          </a>
        </div>
      )}

      <Navbar.Toggle aria-controls="navbar-links" className="ms-auto" />
      <Navbar.Collapse id="navbar-links">
        <Nav className={navbarClass} >
          <Nav.Link href={wolData.urls.homepageUrl}>Trabajos para abogados</Nav.Link>
          <Nav.Link href="http://blog.workon.law">Blog</Nav.Link>
          <Nav.Link href={wolData.urls.employerFrontUrl}>Empleadores</Nav.Link>
          {showSwitch
            ? <Nav.Item>
              <Dropdown>
                <Dropdown.Toggle variant="white" className="dropdown-basic" id="dropdown-countries">
                  {(location.pathname === "/") ? country.names[countryCode] : <ReactCountryFlag countryCode={countryCode} svg />}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {country.codes.map((code, index) => {
                    if (countryCode !== code)
                      return (
                        <Dropdown.Item key={`countryCode-${index}`} onClick={() => { setCountryCode(code) }}>
                          {(location.pathname === "/") ? null : <ReactCountryFlag countryCode={code} svg />}
                          {country.names[code]}
                        </Dropdown.Item>
                      );
                    return null;
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
            : null
          }
          <Nav.Item>
            <Dropdown>
              <Dropdown.Toggle variant="outline-danger" className="dropdown-basic" id="dropdown-session">
                Inicio de sesión
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={wolData.urls.frontUrl}>Candidatos</Dropdown.Item>
                <Dropdown.Item href={wolData.urls.employerFrontUrl + "/sign-in"}>Empleadores</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
          <Nav.Link href={wolData.urls.frontUrl + "/sign-up"}>
            <Button variant="danger" id="login-button">Regístrate</Button>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default withRouter(Header)

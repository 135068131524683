export const names = {
  "CL": "Chile",
  "CO": "Colombia",
  "MX": "México"
};
export const codes = Object.keys(names);

export const getFromIP = async () => {
  const response = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
  const kv = (await response.text()) // get response body
    .split("\n")                     // split into lines
    .map(line => line.split("="))    // separate lines into key and value ("key=value")
    .filter(arr => arr.length > 1);  // there's an empty entry at the end so filter only by complete entries
  /* convert array to JavaScript object */
  return Object.fromEntries(kv).loc;
};
import { Alert, Col, Row } from "react-bootstrap";

const nativeElements = ["column", "row", "section", "title", "subtitle", "list", "paragraph", "img", "strong", "list-title"];

function deepSearch(obj, key) {
  let fnd = null;
  JSON.stringify(obj, (_, val) => {
    if (val && val[key]) fnd = val;
    return val;
  });
  return fnd;
}

const renderInline = (content) => {
  if (typeof content === 'string') {
    return content;
  } else {
    return (
      <>
        {content.map(data => {
          if (typeof data === 'string') {
            return data;
          } else {
            let type = Object.keys(data)[0];
            let content = data[type];
            switch (type) {
              case "br":
                return <br />;
              case "img":
                return <img alt={content.alt} src={content.src} width={content.width} height={content.height} />
              case "link":
                return <a href={content.href}>{content.text}</a>
              default:
                return null;
            }
          }
        })}
      </>
    );
  }
}

const renderElement = (type, content, options) => {
  switch (type) {
    case "column":
      return (
        <>
          <Col md={6} sm={9}>
            {renderDocument(content, options)}
          </Col>
        </>
      )
    case "row":
      return (
        <div style={{ marginTop: '35px' }}>
          <Row >
            {renderDocument(content, options)}
          </Row>
        </div>
      )
    case "section":
      return (
        <div style={{ marginTop: '35px' }}>
          {renderDocument(content, options)}
        </div>
      );
    case "title":
      return (
        <>
          <h2>{content}</h2>
          <hr />
        </>
      );
    case "subtitle":
      return <h3 style={{ marginLeft: '15px', fontSize: '24px' }}>{content}</h3>;
    case "paragraph":
      return <p style={{ marginLeft: '18px' }}>{content}</p>;
    case "list":
      return (
        <ul>{
          content.map((element, index) => <li key={index}>{element}</li>)
        }</ul>
      );
    case "img":
      return (
        <Row>
          <Col className="scrollable-image">
            <img src={content} />
          </Col >
        </Row>
      )
    case "strong":
      return <p><strong style={{ fontSize: '18px' }} className="text-primary">{content}</strong></p>
    case "list-title":
      return <p style={{ marginBottom: '0', marginLeft: '18px' }}>{content}</p>;
    default:
      return null;
  }
}

// class RenderException {
//   constructor(element, index) {
//     this.name = 'RenderException';
//     this.element = element;
//     this.index = index;
//   }

//   toString() {
//     return `Unknown element \`${this.element}\` at block ${this.index}`
//   }
// }

const renderDocument = (data, options) => {
  if (!Array.isArray(data)) {
    data = [data];
  }

  return data.map((element, index) => {
    let type = Object.keys(element)[0];
    let content;
    if (type === 'paragraph') {
      content = renderInline(element[type]);
    } else {
      content = element[type];
    }

    if (nativeElements.includes(type)) {
      return renderElement(type, content, options);
    } else if (Object.keys(options.customElements).includes(type)) {
      return options.customElements[type](content);
    } else {
      return null
      // throw new RenderException(type, index);
    }
  });
}

const Markup = ({ data, options = {} }) => {
  try {
    /* quick fix */
    while (typeof data === "string")
      data = JSON.parse(data);

    if (!data) return null;

    if (options.isEmployer) {
      /* Append employer-specific objects if not specified in content */
      if (!deepSearch(data, "openToHire")) {
        data.unshift({ openToHire: true })
      }
      if (!deepSearch(data, "jobOffers")) {
        data.push({ jobOffers: true })
      }
    }

    let render = renderDocument(data, options);

    if (options.wrap) {
      return options.wrap(render);
    } else {
      return render;
    }
  } catch (ex) {
    return (
      <Alert variant={"danger"}>
        <Alert.Heading>Error!</Alert.Heading>
        <p className="mb-0">
          {ex.toString()}
        </p>
      </Alert>
    );
  }
};

export default Markup;
import React from 'react'
import { Container, Row, Spinner } from "react-bootstrap";

const LoadingSpinner = () => (
  <Container style={{ marginTop: '35px', marginLeft: '80px' }}>
    <Row className='d-flex flex-column align-items-center justify-content-center'>
      <Spinner animation="border" role="status">
        <span className="visually-hidden"> Buscando información ...</span>
      </Spinner>
    </Row>
  </Container>
);

export default LoadingSpinner;
import wolData from './wolData';

const utils = {
  getCandidateAppSignInUrl: (job) => {
    let site = 'CL'
    if(wolData.validSites.includes(job.site)){
      site = job.site
    }
    return wolData.urls.frontUrl + "/sign-up?site=" + site;
  },

  getApplyUrl: (job) => {
    let site = 'CL'
    if(wolData.validSites.includes(job.site)){
      site = job.site
    }

    let url = wolData.urls.frontUrl + "/apply-to?job_id=" + job.id + "&site=" + site;
    if(job.open_to_hire === true){
      url = url + "&open_to_hire_id=" + job.id
    }

    return url;
  }
}

export default utils;

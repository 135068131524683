import { Col, Row } from 'react-bootstrap';
import wolData from '../common/wolData';

const EmployerJobItem = ({ job }) => {
  if ((job.state === 'published' || job.state === 'preview') && (job.open_to_hire !== true)) {
    return (
      <Row
        className="align-items-center mh-100"
        style={{
          borderBottom: '1px solid #E5E5E5',
          paddingBottom: '10px',
          marginTop: '10px',
          marginBottom: '10px',
          height: '100px'
        }}
      >
        {/* Job title*/}
        <Col md={9} xs={12}>
          <span
            className="job-title-link"
            style={{
              fontFamily: 'Open Sans',
              fontSize: '20px',
              fontWeight: 700,
              fontStyle: 'normal',
              lineHeight: '25px',
              color: '#191919'
            }}>
            <a href={wolData.urls.homepageUrl + "/" + job.url}>
              {job.title}
            </a>
          </span>
          <div className="info-md-hidden">
            {job.subtitle}
          </div>
        </Col>
        <Col className="info-xs-hidden" md={3}>
          <a
            href={wolData.urls.homepageUrl + "/" + job.url}
            target="_blank"
            rel="noreferrer"
            ole="button"
            className="btn btn-outline-danger"
          >
            Ver oferta
          </a>
        </Col>
      </Row>
    )
  } else {
    return null;
  }
}

export default EmployerJobItem;
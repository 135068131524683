import React, { useContext, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Alert
} from 'react-bootstrap';

import wolData from '../common/wolData';
import LocaleContext from "./localeContext";
import { useFetch } from "react-async";
import LoadingSpinner from "./loadingSpinner";

const EmployersOpenToHire = () => {
  const { countryCode: site, setShowSwitch } = useContext(LocaleContext);
  useEffect(() => {
    setShowSwitch(true);
  }, [setShowSwitch])
  const { data, error, isPending } = useFetch(
    wolData.urls.homepageUrl + `/all-open-to-hire.json`,
    { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
  );
  if (isPending) return <LoadingSpinner />
  if (error) {
    return <Alert variant="danger">
      <b>ERROR:</b> {error.message}
    </Alert>;
  }

  return (
    <Container id='employers-open-to-hire' style={{ marginTop: '50px' }}>
      <Row><Col><h2>Postulación y Carrera</h2></Col></Row>
      <Row>
        <Col>
          <h4 style={{ marginTop: '35px', fontSize: '20px', color: '#960F0F' }}>¿Quieres que tus antecedentes profesionales sean visibles a alguno de los siguientes empleadores?</h4>
          <br />
          <p>A continuación podrás ver un listado con oficinas de abogados de alto nivel que tienen interés por estar en contacto con el talento legal. También encontrarás en la lista a reclutadores habituales como Idealis Legal Recruitment. En esta sección podrás postular a procesos activos con ellos, o bien dejar tus antecedentes para que sean considerados en futuros procesos de selección.</p>
        </Col>
      </Row>

      <Row className="align-items-center" style={{ marginTop: '50px' }}>
        {data[site].employers_open_to_hire.map((e, i) => (
          (e.site === site) ?
            <Col lg={4} md={6} xs={12} key={`employer-${i}`}>
              <a href={wolData.urls.homepageUrl + '/empleadores/' + e.landing_url}>
                <div className="open-to-hire-item" >
                  <img
                    alt={`Logo de empleador ${e.name}`}
                    src={e.logo_url}>
                  </img>
                </div>
              </a>
            </Col>
            :
            null
        ))
        }
      </Row>
    </Container>
  )

}
export default EmployersOpenToHire;
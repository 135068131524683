import { Col, Row, } from 'react-bootstrap';

import EmployerJobItem from "./EmployerJobItem";

const EmployerJobs = ({ employer, employerId }) => {

  const getEmployerJobs = (allJobs, employerUrl) => {
    return allJobs.filter(j => (j.employer.landing_url === employerUrl));
  }

  return (
    <Col className="mt-5">
      <Row >
        <h2>Ofertas de trabajo</h2>
      </Row>
      <Row>
        {(employer.listing_text) ? <p>{employer.listing_text}</p> : null}
      </Row>
      <Row>
        <div id="employer-job-listing">
          {getEmployerJobs(employer.employer_jobs, employerId).map((job, index) => (
            <EmployerJobItem job={job} key={`job-item-${index}`} />
          ))}
        </div>
      </Row>
    </Col>
  )
}

export default EmployerJobs;
import React, { useContext, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Image,
  Button
} from 'react-bootstrap';

import salaryGuideImage1 from "../images/salary_guide_image_1.png"
import wolData from '../common/wolData';
import LocaleContext from "./localeContext";

const SalaryGuide = () => {
  const { setShowSwitch } = useContext(LocaleContext);
  useEffect(() => {
    setShowSwitch(false);
  }, [setShowSwitch])

  return (
    <React.Fragment>
      <Container id='employers-open-to-hire' style={{ marginTop: '50px' }}>
        <Row>
          <Col>
            <h2>Sueldos en oficinas grandes y medianas</h2>
          </Col>
        </Row>

        <Row style={{ marginTop: '60px' }}>
          <Col xs='12' md='4' style={{ marginTop: '-55px' }}>
            <Image src={salaryGuideImage1} width='350px' height='350px' />
          </Col>
          <Col xs='12' md='8'>
            <p>Durante años <a className="text-primary" href="http://idealis.cl/">Idealis</a> entregó, mediante su Guía Salarial, los rangos de sueldo de los abogados con hasta 5 años de experiencia en estudios jurídicos grandes y medianos.</p>
            <p>Desde ahora en adelante y con el fin de aportar una información más precisa, <a className="text-primary" href={wolData.urls.homepageUrl}>Work On Law</a> e <a className="text-primary" href="http://idealis.cl/">Idealis</a> preparan la Guía Salarial en conjunto. Los abogados podrán saber cuánto ganan otros profesionales con un perfil similar al suyo; y los empleadores tendrán una mejor idea de si están pagando "sueldos de mercado".</p>

            <Row style={{ marginTop: '35px', marginRight: '15px' }}>
              <Col xs='12' md="4" className="text-center" style={{ backgroundColor: '#F1F4FF', padding: '25px', margin: '5px' }}>
                <strong style={{ fontSize: '18px', color: '#960F0F' }}>Empleadores</strong>
                <br />
                <br />
                <p>La guía salarial se encuentra disponible para todos los suscriptores premium de Work On Law.</p>
                <br />
                <Button href={wolData.urls.employerFrontUrl} variant="outline-primary">Ver información</Button>
              </Col>
              <Col xs='12' md="7" className="text-center" style={{ backgroundColor: '#F1F4FF', padding: '25px', margin: '5px' }}>
                <strong style={{ fontSize: '18px', color: '#960F0F' }}>Abogados y Procuradores</strong>
                <br />
                <br />
                <p>Para acceder a esta información necesitas una cuenta de Work On Law. Si ya tienes una solo debes iniciar sesión. Si aún no tienes una cuenta, puedes registrarte y crear una.</p>
                <br />
                <Button href={wolData.urls.frontUrl + "/sign-in"} style={{ marginRight: '10px' }} variant="outline-danger">Inicio de sesión</Button>
                <Button href={wolData.urls.frontUrl + "/sign-up"} variant="danger">Regístrate</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default SalaryGuide;
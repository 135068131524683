import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";

import * as country from './common/country'

(async () => {
  if (window.localStorage.getItem('country-code') === null) {
    let countryCode = await country.getFromIP();
    if (!country.codes.includes(countryCode)) countryCode = 'CL';
    window.localStorage.setItem('country-code', countryCode)
  }

  ReactDOM.render(
    <React.StrictMode>
      <Router><App /></Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  Alert
} from "react-bootstrap";
import LocaleContext from "./localeContext";
import { useFetch } from "react-async";
import LoadingSpinner from "./loadingSpinner";

import BlogItem from './BlogItem';
import wolData from '../common/wolData';

const BlogSection = () => {
  const { countryCode } = useContext(LocaleContext);

  const { data: _data, error, isPending } = useFetch(
    wolData.urls.homepageUrl + `/blog-posts.json`,
    { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
  );
  if (isPending) return <LoadingSpinner />
  if (error) {
    return "";
  }

  const blogData = _data[countryCode];

  return (
    <Container fluid id="blog">
      <Container fluid>
        <Row className="text-center justify-content-center align-self-center mb-5">
          <Col
            id="last-posts-tag"
            sm={6}
            lg={4}>
            <h2>Últimos Posts</h2>
          </Col>
        </Row>
      </Container>
      <Container id='blog-section'>
        <Carousel variant={"dark"} interval={null}>
          <CarouselItem>
            <Container fluid id="card-list">
              {blogData
                .slice(0, 3)
                .map((post, i) => <BlogItem {...post} key={`post-${i}`} />)}
            </Container>
          </CarouselItem>

          <CarouselItem>
            <Container fluid id="card-list">
              {blogData
                .slice(3, 6)
                .map((post, i) => <BlogItem {...post} key={`post-${i}`} />)}
            </Container>
          </CarouselItem>

          <CarouselItem>
            <Container fluid id="card-list">
              {blogData
                .slice(6, 9)
                .map((post, i) => <BlogItem {...post} key={`post-${i}`} />)}
            </Container>
          </CarouselItem>
        </Carousel>
      </Container>

      <Container id='blog-mobile-section'>
        <Carousel variant={"dark"} interval={null}>
          {blogData.map((post, i) =>
            <CarouselItem key={`post-mobile-${i}`}>
              <Container fluid id="card-list">
                <BlogItem {...post} />
              </Container>
            </CarouselItem>
          )}
        </Carousel>
      </Container>
    </Container>
  )
}

export default BlogSection;

import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Badge,
  Button,
  Modal, Alert
} from 'react-bootstrap';

import wolData from '../common/wolData';
import LocaleContext from "./localeContext";
import { useFetch } from "react-async";
import LoadingSpinner from "./loadingSpinner";

const getEmployerJobs = (allJobs, employerUrl) => {
  return allJobs.filter(j => (j.employer.landing_url === employerUrl));
}

const getEmployer = (employers, employerUrl) => {
  return employers.find(e => (e.landing_url === employerUrl));
}

const normalizeData = (rawData, employerUrl) => {
  const employer = getEmployer(rawData.employers, employerUrl);
  rawData["employer"] = employer;
  rawData["employer_jobs"] = employer.employer_jobs
  return rawData
}

const EmployerPremium = () => {
  const [showJobModal, setShowJobModal] = useState(null);

  const ListItemJob = (props) => {
    if (props.job.state === 'published' || props.job.state === 'preview') {
      return (
        <Row
          className="align-items-center mh-100"
          style={{
            borderBottom: '1px solid #E5E5E5',
            paddingBottom: '10px',
            marginTop: '10px',
            marginBottom: '10px',
            height: '100px'
          }}
        >
          {/* Job title*/}
          <Col md={6} xs={12}>
            <span
              className="job-title-link"
              style={{
                fontFamily: 'Open Sans',
                fontSize: '20px',
                fontWeight: 700,
                fontStyle: 'normal',
                lineHeight: '25px',
                color: '#191919'
              }}>
              <a href="#" onClick={() => setShowJobModal(props.job.url)}>
                {props.job.title}
              </a>
            </span>
            <div className="info-md-hidden">
              {props.job.subtitle}
            </div>
          </Col>
          <Col className="info-xs-hidden" md={3}>{props.job.subtitle}</Col>
          <Col className="info-xs-hidden" md={3}>
            <Button variant="outline-danger" onClick={() => setShowJobModal(props.job.url)}>Ver oferta</Button>
            <JobModal job={props.job} showJobModal={showJobModal} />
          </Col>
        </Row>
      )
    } else if (props.job.state === "closed") {
      return (
        <Row style={{
          borderBottom: '1px solid #E5E5E5',
          paddingBottom: '10px',
          marginTop: '25px',
          marginBottom: '25px'
        }}>
          <Col md={4} xs={8}>
            <span
              style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '25px',
                color: '#B7B4B5'
              }}>
              {props.job.employer.name}
            </span>
            <br />
            <span
              style={{
                fontFamily: 'Open Sans',
                fontSize: '20px',
                fontWeight: 700,
                fontStyle: 'normal',
                lineHeight: '25px',
                color: '#B7B4B5'
              }}>
              {props.job.title}
            </span>
            <div style={{ color: '#B7B4B5' }} className="info-md-hidden">{props.job.subtitle}</div>
          </Col>
          <Col style={{ color: '#B7B4B5' }} className="info-xs-hidden" md={3}>{props.job.subtitle}</Col>
          <Col className="info-xs-hidden" md={2}><Badge variant='secondary'>Contratado</Badge></Col>
        </Row>
      )
    } else {
      return null;
    }
  }

  const JobModal = (props) => {
    const [show] = useState(props.job.url === props.showJobModal)
    const handleClose = () => setShowJobModal(null);

    return (
      <Modal size="xl" show={show} onHide={handleClose}>

        <Modal.Header closeButton>
        </Modal.Header>

        <Container style={{ marginBottom: '100px' }} fluid>
          <Row id="job-offer-banner-container" style={{ backgroundImage: "url(/job-offer-background.jpg)", marginLeft: '-16px' }}>
            <Col md={6} sm={9}>
              <div id="job-offer-banner">
                <h2>{props.job.title}</h2>
                <p id="job-offer-subtitle">{props.job.subtitle}</p>
                <br />
                <a href={wolData.urls.frontUrl + "/sign-up"} className="btn btn-danger btn-lg">Postular</a>
              </div>
            </Col>
            <Col xs={3} />
          </Row>
          <Row>
            <Col>
              <div id="job-offer-description">
                <h2>Requisitos</h2>
                <hr />
                <ul>
                  {props.job.requirements.map((requirement) => (
                    <li>{requirement}</li>
                  ))}
                </ul>

                {/* Salary */}
                {props.job.salary ?
                  (
                    <React.Fragment>
                      <br />
                      <h2>Remuneración</h2>
                      <hr />
                      <ul>
                        <li>{props.job.salary}</li>
                      </ul>
                    </React.Fragment>
                  )
                  :
                  null
                }

              </div>
            </Col>
          </Row>
        </Container>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const { employerId } = useParams();
  const { countryCode, setShowSwitch } = useContext(LocaleContext);
  useEffect(() => {
    setShowSwitch(false);
  }, [setShowSwitch])
  const { data: _data, error, isPending } = useFetch(
    wolData.urls.homepageUrl + `/all-employers.json`,
    { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
  );
  if (isPending) return <LoadingSpinner />
  if (error) {
    return <Alert variant="danger">
      <b>ERROR:</b> {error.message}
    </Alert>;
  }

  let data = normalizeData(_data, employerId);

  if (employerId !== 'philippi-prietocarrizosa-ferrero-du-uria-ppu') {
    return null;
  }

  return (
    <React.Fragment>
      <Container id="employer-premium-landing-container">
        <Row>
          <Col>
            <h2>{data.employer.name}</h2>
            <hr />
            <br />
            <br />
            <Row>
              <Col sm={5} md={4} lg={3}>
                <div style={{ height: '200px', width: '200px' }}>
                  <img alt={`Logo de empleador ${data.employer.name}`} width={data.employer.logo_width} height={data.employer.logo_height} src={data.employer.logo_url} />
                </div>
              </Col>
              <Col sm={7} md={8} lg={9}>
                <p><strong>PPU</strong> es un estudio full service, con oficinas propias en Santiago (Chile), Bogotá y Barranquilla (Colombia) y Lima (Perú). Uría Menéndez aporta a PPU su red de oficinas en España, Portugal, Sao Paulo, Nueva York, Pekín, Londres y Bruselas.</p>
                <p>El estudio busca responder de manera eficiente a las nuevas demandas de servicios profesionales que supone la creciente interrelación económica entre los países que conforman la Alianza del Pacífico (Chile, Colombia, México y Perú), sumado al progresivo interés de empresas de Europa, Asia y Norteamérica en la región de Latinoamérica.</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p><strong style={{ fontSize: '18px' }} className="text-danger">¿A quiénes buscamos?</strong></p>
                <p>Buscamos profesionales que combinen el talento con la disciplina, personas que estén comprometidas con su profesión, con ganas de aprender y hacerse una carrera junto a los mejores abogados de la región.</p>
              </Col>
            </Row>

            <br />
            <Row>
              <Col>
                <p><strong style={{ fontSize: '18px' }} className="text-danger">¿Qué ofrecemos?</strong></p>

                <p>
                  1.- PROGRAMA DE INTERCAMBIO
                  <br />
                  Nuestra presencia regional ofrece una gran oportunidad para todos los abogados de PPU. Todos los años los asociados pueden trasladarse a trabajar de manera temporal a Colombia y Perú.
                </p>

                <p>
                  2.-POSTGRADOS Y TRABAJOS EN EL EXTRANJERO
                  <br />
                  Impulsamos a nuestros abogados a especializarse con estudios en el extranjero y posteriormente realizar pasantías en otros países.
                </p>

                <p>
                  3.- CAMPUS PPU
                  <br />
                  Buscamos el desarrollo integral de nuestros abogados y contamos con un programa exclusivo de capacitación, especialmente diseñado en base a las necesidades de los asociados en los distintos momentos de su carrera.
                </p>
              </Col>
            </Row>


          </Col>
        </Row>

        <Row>
          <Col className="scrollable-image">
            <img src="https://wol-logos.s3.amazonaws.com/ppu-stats.jpg" alt="ppu-stats" />
          </Col>
        </Row>

        <Row>
          <Col className="scrollable-image">
            <img src="https://wol-logos.s3.amazonaws.com/ppu-specialities.jpg" alt="ppu-specialities" />
          </Col>
        </Row>

        <br />
        <br />
        <br />
        <Row>
          <Col>
            <h2>Ofertas de trabajo</h2>
            <hr />

            <div id="employer-job-listing">
              {getEmployerJobs(data.employer_jobs, employerId).map((job) => (
                <ListItemJob job={job} employer={data.employer} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );

}
export default EmployerPremium;

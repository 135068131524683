import { Col, Row } from 'react-bootstrap';
import wolData from '../common/wolData';


const OpenToHireSection = ({ employer }) => {
  if (employer.open_to_hire_job && employer.open_to_hire_job.id) {
    const job = employer.open_to_hire_job

    return (
      <Col style={{ backgroundColor: '#F4F7FF', marginTop: '25px', marginBottom: '35px', padding: '70px', }}>
        <Row>
          <Col>

            <Row >
              <h2 style={{ color: '#960F0F', paddingLeft: '20px', marginBottom: '10px' }}>
                ¿Quieres ser considerado en futuras ofertas de trabajo?
              </h2>
            </Row>
            <Row >
              <p style={{ color: '#666666', fontSize: '18px', paddingLeft: '20px' }}>{job.title}</p>
            </Row>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <a
              href={wolData.urls.homepageUrl + "/" + job.url}
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-outline-primary"
            >
              Inscribir mis datos
            </a>
          </Col>
        </Row>
      </Col>
    )
  } else {
    return null;
  }
}


export default OpenToHireSection;
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import wolData from "../common/wolData";
import wolBotImg from "../images/wolbot.png"
import teamImg from "../images/team.jpeg"
import amrImg from "../images/arm.png"
import workingImg from "../images/working.png"
import moneyImg from "../images/money.png"
import bannerLawFirmsImg from "../images/banner-law-firms.png"
import bannerCompaniesImg from "../images/banner-companies.png"
import foundersImg from "../images/founders.png"

const HuntingLanging = ({ employerType }) => {
  const price = (employerType === "company") ? '3000' : '2000';

  return (
    <Container id={"hunting-container"}>
      <Row style={{ marginTop: '75px' }}>
        <Col>
          <h1>Work On Law Hunting</h1>
        </Col>
      </Row>

      {/* Section 1: ¿Buscas un abogado(a) para tu equipo? */}
      <Row style={{ marginTop: '75px' }}>
        <Col xs="12" md="6">
          <Image
            style={{ marginLeft: '35px', marginBottom: '25px' }}
            src={wolBotImg}
            width="80%"
          />
        </Col>
        <Col xs="12" md="6">
          <h3>¿Buscas un abogado(a) para tu equipo?</h3>
          <p className="hunting-landing-subtitle">Work On Law Hunting resuelve tu problema. Te presentamos a Harvey, nuestro robot experto en selección que te entregará una lista larga en solo 96 horas.</p>
          <p className="hunting-landing-text">Revisa aquí qué procesos de selección que se están haciendo a través de <a target="_blank" rel="noreferrer" className="hunting-container-link" href={wolData.urls.homepageUrl}>Work On Law</a> en 3 países.</p>

          <Row style={{ marginTop: '50px' }}>
            <Col xs="12" className="d-grid">
              <Button
                href="https://workonlaw.typeform.com/to/GCmVmnOO"
                target="_blank"
                rel="noreferrer"
                variant="primary"
                className="hunting-container-button btn-block"
              >
                Probar herramientas gratuitas
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Section 2: Tú te encargas de lo humano */}
      <Row style={{ marginTop: '120px' }}>
        <Col xs="12" md="7">
          <h3>Tú te encargas de lo humano</h3>
          <p className="hunting-landing-subtitle">Te liberamos del papeleo para que puedas poner el toque humano que solo tú puedes traer: entrevistar a los postulantes, ratificar su calce, cultura y más.</p>
          <p className="hunting-landing-text">El proceso de selección de un abogado(a) puede resultar complejo y extenso. Tienes que revisar cientos de postulantes (muchos de ellos ni si quiera son abogados), y filtrar manualmente por universidad, años de experiencia, especialidad, pretensiones de renta, nivel de inglés y otros factores. ¿Por qué no dejarle ese trabajo duro a Harvey? Nuestro robot ha sido entrenado por abogados especialistas en selección para hacer el trabajo mecánico donde no se necesita tu toque humano.</p>
        </Col>
        <Col xs="12" md="5">
          <Image src={teamImg} width="90%" style={{ marginTop: '35px' }} />
        </Col>
      </Row>

      {/* Section 3: ¿Cómo funciona Work On Law Hunting? */}
      <Row style={{ marginTop: '100px' }}>
        <Col xs="12" md="4">
          <Image src={amrImg} width="50%" />
        </Col>
        <Col xs="12" md="8">
          <h3>¿Cómo funciona Work On Law Hunting?</h3>
          <p className="hunting-landing-subtitle">En 4 pasos cortos conseguimos una lista larga con los abogados mejor calificados para la posición que necesitas.</p>
          <ol type="1">
            <li>Primero debes llenar un formulario (¡no te preocupes, dura máximo 6 minutos!) con el detalle del perfil que estás buscando: <a target="_blank" rel="noreferrer" className="hunting-container-link" href="https://workonlaw.typeform.com/to/VHjGgmDl">Encontrarás el formulario aquí.</a></li>
            <li>Cuando sepamos cuál es el perfil que buscas publicaremos el aviso y le daremos la difusión que se merece.</li>
            <li>Aquí es donde Harvey viene a ayudarte. Usando nuestra tecnología Knock Knock®, identificará a quienes mejor cumplen el perfil dentro de nuestros 20 mil abogados inscritos y los invitará a postularse.</li>
            <li>Nuestro algoritmo ponderará el CV, especialidades, años de ejercicio, rasgos de personalidad, habilidades cognitivas, idiomas, pretensión de renta y más. Finalmente, uno de los abogados de nuestro equipo revisará los nombres sugeridos por Harvey y dará el visto bueno final a tu lista. En ese momento estarán disponibles los entregrables que señalamos a continuación.</li>
          </ol>
        </Col>
      </Row>

      {/* Section 4: Entregable a las 96 horas*/}
      <Row style={{ marginTop: '100px' }}>
        <Col xs="12" md="4">
          <Image src={workingImg} width="50%" />
        </Col>
        <Col xs="12" md="8">
          <h3>Entregable a las 96 horas</h3>
          <p className="hunting-landing-subtitle">Tus horas valen dinero</p>
          {/* <p className="hunting-landing-text">Te entregaremos lo siguiente:</p> */}
          <ol type="1">
            <li>Lista larga con los 5 o 10 postulantes que mejor cumplen el perfil.</li>
            <li>Acceso a los datos de contacto, CV, informes psicolaborales, pretensión de renta y otros antecedentes de los seleccionados.</li>
            <li>Informe de la escasez del perfil buscado y de la tracción de tu marca empleadora.</li>
            <li>Hasta 5 certificaciones de inglés (costo extra).</li>
            <li>Banda de sueldos aplicables al cargo (costo extra).</li>
          </ol>
        </Col>
      </Row>

      {/* Section 5: Precio según tus necesidades */}
      <Row style={{ marginTop: '100px' }}>
        <Col xs="12" md="4">
          <Image src={moneyImg} width="50%" />
        </Col>
        <Col xs="12" md="8">
          <h3>Precio según tus necesidades</h3>

          <p className="hunting-landing-subtitle">Lista larga</p>
          <p className="hunting-landing-text">Desde USD {price} + IVA obtendrás tu lista larga con los 5 a 10 abogados que mejor cumplen el perfil que pediste.</p>

          <p className="hunting-landing-subtitle">Complementos</p>
          <ol type="1">
            <li>Podrás obtener la certificación de inglés de 5 candidatos que tu elijas por un pago adicional de USD 500 + IVA.</li>
            <li>Nuestra Brújula Salarial® te informará cuál es la renta de mercado para el cargo publicado por un pago adicional de USD 500 + IVA.</li>
            <li>Puedes realizar todo este proceso de manera completamente anónima por un pago adicional de USD 1000 + IVA.</li>
          </ol>
        </Col>
      </Row>

      {/* Section 6: Call to action */}
      <Row style={{ marginTop: '50px', padding: '0 50px 0 50px' }}>
        <Col xs="12 d-grid">
          <Button href="mailto:juanpablo@workon.law" variant="primary" className="hunting-container-button btn-block">Quiero que me contacten</Button>
        </Col>
      </Row>

      {/* Section 7: Banner */}
      <Row style={{ marginTop: '100px', padding: '35px' }}>
        <Col xs="12">
          {(employerType === "company") ?
            <Image src={bannerCompaniesImg} width="100%" />
            :
            <Image src={bannerLawFirmsImg} width="100%" />
          }
        </Col>
      </Row>

      {/* Section 8: Founders */}
      <Row style={{ marginTop: '100px', padding: '35px' }}>
        <Col xs="12" md="6">
          <Image src={foundersImg} width="75%" />
        </Col>
        <Col xs="12" md="6">
          <p style={{ color: "#960F0F", fontSize: "20px", fontWeight: '500' }}>Conoce a los co-fundadores de Work On Law:</p>
          <p><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/idealis/">Felipe Lavín</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/carlos-moreno-7470636/">Carlos Moreno</a></p>
          <p><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/vladimir-suarez-perez/">Vladimir Suárez</a></p>
        </Col>
      </Row>
    </Container>

  )
}
export default HuntingLanging;

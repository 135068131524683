import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import EmployerPremium from "./employerPremium";

const Employers = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:employerId`}>
        <EmployerPremium />
      </Route>
    </Switch>
  )
}
export default Employers;

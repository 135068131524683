import { Button, Col, Container, Row } from "react-bootstrap";

import wolData from "../common/wolData";

const NewsletterForm = ({ site, location }) => {
  if (wolData.validSites.includes(site)) {
    return (location === "home") ? (
      <Container fluid id="mail-offers">
        <div id="mail-offers-title" className="offer-section" href="#mail-offers-title">
          <div id="mail-offers-title-inner">
            <h2>{wolData.newsletterForm.translations.title[site]}</h2>
          </div>
        </div>
        <div id="mc_embed_signup" className="contact-form">
          <form action={wolData.newsletterForm.urls[site]} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">
                <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email" />
              </div>
              <div className="mc-field-group">
                <input type="text" defaultValue="" name="FNAME" className="required" id="mce-FNAME" placeholder="Nombre" />
              </div>
              <div className="mc-field-group">
                <input type="text" defaultValue="" name="LNAME" className="required" id="mce-LNAME" placeholder="Apellido" />
              </div>
              <div hidden={true}><input type="hidden" name="tags" readOnly={true} defaultValue={wolData.newsletterForm.tags[site]} /></div>
              <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
              </div>
              <div style={{ position: "absolute", "left": "-5000px" }} aria-hidden="true"><input type="text" name={wolData.newsletterForm.antispam[site]} tabIndex="-1" readOnly={true} defaultValue="" /></div>
              <div className="clear" style={{ paddingTop: "10px" }}>
                <Button variant="danger" type="submit" id="mc-embedded-subscribe">{wolData.newsletterForm.translations.submitButton[site]}</Button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    ) : (
      <Row style={{ backgroundColor: '#F1F4FF', padding: '20px', marginTop: '35px' }}>
        <Col sm="7" className="text-center justify-content-center align-self-center">
          <h2>{wolData.newsletterForm.translations.title[site]}</h2>
        </Col>

        <Col className="text-center justify-self-center align-self-center">
          <div id="mc_embed_signup">
            <form action={wolData.newsletterForm.urls[site]} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                  <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email" />
                </div>
                <div className="mc-field-group">
                  <input type="text" defaultValue="" name="FNAME" className="required" id="mce-FNAME" placeholder="Nombre" />
                </div>
                <div className="mc-field-group">
                  <input type="text" defaultValue="" name="LNAME" className="required" id="mce-LNAME" placeholder="Apellido" />
                </div>
                <div hidden={true}><input type="hidden" name="tags" readOnly={true} defaultValue={wolData.newsletterForm.tags[site]} /></div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                  <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                </div>
                <div style={{ position: "absolute", "left": "-5000px" }} aria-hidden="true"><input type="text" name={wolData.newsletterForm.antispam[site]} tabIndex="-1" readOnly={true} defaultValue="" /></div>
                <div className="clear" style={{ paddingTop: "10px" }}>
                  <Button variant="danger" type="submit" id="mc-embedded-subscribe">{wolData.newsletterForm.translations.submitButton[site]}</Button>
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    )
  } else {
    return null
  }
}

export default NewsletterForm;
const wolData = {
  validSites: ['CL', 'CO', 'MX'],
  urls: {
    homepageUrl: process.env.REACT_APP_HOMEPAGE_URL,
    frontUrl: process.env.REACT_APP_WOL_FRONT_URL,
    employerFrontUrl: process.env.REACT_APP_WOL_EMPLOYER_FRONT_URL,
    mailchimpSubscribeForm: {
      "CL": "https://law.us6.list-manage.com/subscribe/post?u=ac12b9230c6465175cec866c1&amp;id=d2cea1bbb8",
      "CO": "https://law.us6.list-manage.com/subscribe/post?u=ac12b9230c6465175cec866c1&amp;id=fae811df86"
    }
  },
  newsletterForm: {
    urls: {
      CL: "https://law.us6.list-manage.com/subscribe/post?u=ac12b9230c6465175cec866c1&amp;id=d2cea1bbb8",
      CO: "https://law.us6.list-manage.com/subscribe/post?u=ac12b9230c6465175cec866c1&amp;id=fae811df86",
      MX: "https://law.us6.list-manage.com/subscribe/post?u=ac12b9230c6465175cec866c1&amp;id=fae811df86"
    },
    tags: {
      CL: "3224377",
      CO: "3223489",
      MX: "3223493"
    },
    antispam: {
      CL: "b_ac12b9230c6465175cec866c1_d2cea1bbb8",
      CO: "b_ac12b9230c6465175cec866c1_fae811df86",
      MX: "b_ac12b9230c6465175cec866c1_fae811df86"
    },
    translations: {
      title: {
        CL: "¿Quieres recibir las ofertas de la semana?",
        CO: "¿Quieres recibir nuestras alertas legales?",
        MX: "¿Quieres recibir nuestras alertas legales?"
      },
      submitButton: {
        CL: "Quiero recibir ofertas",
        CO: "Quiero recibir alertas",
        MX: "Quiero recibir alertas"
      }
    }
  },
  blogPosts: {
    CL: [
      {
        date: "24/07/2023",
        text: "Sistematización del conocimiento humano: Las herramientas que aportan y dividen",
        link: "https://blog.workon.law/2023/07/24/sistematizacion-del-conocimiento-humano-las-herramientas-que-aportan-y-dividen/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/07/Sistematizacion-scaled-uai-258x172.jpg"
      },
      {
        date: "13/11/2023",
        text: "Metaverso y comercio virtual: el espacio contemporáneo para compra y venta de bienes, productos y servicios.",
        link: "https://blog.workon.law/2023/11/13/metaverso-y-comercio-virtual-el-espacio-contemporaneo-para-compra-y-venta-de-bienes-productos-y-servicios/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/11/Metaverso-uai-258x172.jpg"
      },
      {
        date: "06/11/2023",
        text: "El rayado de cancha: la ley en el deporte",
        link: "https://blog.workon.law/2023/11/06/el-rayado-de-cancha-la-ley-en-el-deporte/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/11/Deporte-uai-258x172.jpg"
      },
      {
        date: "09/06/2023",
        text: "El uso de la Inteligencia Artificial: Un dilema ético y laboral",
        link: "https://blog.workon.law/2023/06/09/el-uso-de-la-inteligencia-artificial-un-dilema-etico-y-laboral/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/06/Uso-de-IA-scaled-uai-258x172.jpg"
      },
      {
        date: "12/05/2023",
        text: "Abogados espaciales: Un futuro sin límites necesita a la ley",
        link: "https://blog.workon.law/2023/05/12/abogados-espaciales-un-futuro-sin-limites-necesita-a-la-ley/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/05/Espacio-y-abogados-scaled-uai-258x172.jpg"
      },
      {
        date: "06/02/2023",
        text: "Las redes sociales y la relación abogado-cliente",
        link: "https://blog.workon.law/2023/02/06/las-redes-sociales-y-la-relacion-abogado-cliente/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/02/Redes-sociales-vs-Google-scaled-uai-258x172.jpg"
      },
      {
        date: "30/10/2023",
        text: "El Derecho Internacional Humanitario: Protegiendo a la Humanidad en Tiempos de Guerra",
        link: "https://blog.workon.law/2023/10/30/el-derecho-internacional-humanitario-protegiendo-a-la-humanidad-en-tiempos-de-guerra/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/10/Derecho-Internacional-uai-258x172.jpg"
      },
      {
        date: "27/11/2023",
        text: "Las leyes asociadas al bien raíz: desafíos complejos y afectaciones personales",
        link: "https://blog.workon.law/2023/11/27/las-leyes-asociadas-al-bien-raiz-desafios-complejos-y-afectaciones-personales/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/11/Bienes-Raices-scaled-uai-258x172.jpg"
      },
      {
        date: "10/07/2023",
        text: "En su propia ley",
        link: "https://blog.workon.law/2023/07/10/en-su-propia-ley-2023/",
        photo: "https://blog.workon.law/wp-content/uploads/2022/12/AMesa-de-trabajo-8-copia-2@3x-uai-258x172.png"
      }
    ], 
    CO: [
      {
        date: "07/12/2023",
        text: "Desafíos en el mundo legal: hoy y en el futuro.",
        link: "https://blog.workon.law/2023/12/07/desafios-en-el-mundo-legal-hoy-y-en-el-futuro/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/12/Desafios-Firmas-uai-258x172.jpg"
      },
      {
        date: "10/10/2023",
        text: "E-comerce y ciberseguridad: el ámbito legal en un mundo sin límites",
        link: "https://blog.workon.law/2023/10/10/e-comerce-y-ciberseguridad-el-ambito-legal-en-un-mundo-sin-limites/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/10/E-commerce-uai-258x172.jpg"
      },
      {
        date: "22/12/2023",
        text: "Abogados ¿Inglés fluido o falso?",
        link: "https://blog.workon.law/2023/12/22/abogados-ingles-fluido-o-falso/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/12/English-scaled-uai-258x172.jpg"
      },
      {
        date: "13/11/2023",
        text: "Metaverso y comercio virtual: el espacio contemporáneo para compra y venta de bienes, productos y servicios.",
        link: "https://blog.workon.law/2023/11/13/metaverso-y-comercio-virtual-el-espacio-contemporaneo-para-compra-y-venta-de-bienes-productos-y-servicios/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/11/Metaverso-uai-258x172.jpg"
      },
      {
        date: "12/05/2023",
        text: "Abogados espaciales: Un futuro sin límites necesita a la ley",
        link: "https://blog.workon.law/2023/05/12/abogados-espaciales-un-futuro-sin-limites-necesita-a-la-ley/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/05/Espacio-y-abogados-scaled-uai-258x172.jpg"
      },
      {
        date: "17/03/2023",
        text: "Brecha Salarial: Una división por tradición",
        link: "https://blog.workon.law/2023/03/17/brecha-salarial-una-division-por-tradicion/",
        photo: "https://blog.workon.law/wp-content/uploads/2021/10/Foto-abogados-scaled-uai-258x172.jpg"
      },
      {
        date: "25/09/2023",
        text: "El complejo mundo de las siglas legales",
        link: "https://blog.workon.law/2023/09/25/el-complejo-mundo-de-las-siglas-legales/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/09/Siglas-uai-258x172.jpg"
      },
      {
        date: "20/09/2023",
        text: "La sostenibilidad de negocio de un estudio de abogados, ¿estás preparado?",
        link: "https://blog.workon.law/2023/09/20/la-sostenibilidad-de-negocio-de-un-estudio-de-abogados-estas-preparado/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/09/Economia-Abogados-scaled-uai-258x172.jpg "
      },
      {
        date: "04/08/2023",
        text: "Desde Egipto y Babilonia hasta Latinoamérica: el origen de los abogados",
        link: "https://blog.workon.law/2023/08/04/desde-egipto-y-babilonia-hasta-latinoamerica-el-origen-de-los-abogados/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/08/Historia-de-Abogados-scaled-uai-258x172.jpg"
      }
    ],
    MX: [
      {
        date: "07/12/2023",
        text: "Desafíos en el mundo legal: hoy y en el futuro.",
        link: "https://blog.workon.law/2023/12/07/desafios-en-el-mundo-legal-hoy-y-en-el-futuro/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/12/Desafios-Firmas-uai-258x172.jpg"
      },
      {
        date: "10/10/2023",
        text: "E-comerce y ciberseguridad: el ámbito legal en un mundo sin límites",
        link: "https://blog.workon.law/2023/10/10/e-comerce-y-ciberseguridad-el-ambito-legal-en-un-mundo-sin-limites/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/10/E-commerce-uai-258x172.jpg"
      },
      {
        date: "22/12/2023",
        text: "Abogados ¿Inglés fluido o falso?",
        link: "https://blog.workon.law/2023/12/22/abogados-ingles-fluido-o-falso/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/12/English-scaled-uai-258x172.jpg"
      },
      {
        date: "13/11/2023",
        text: "Metaverso y comercio virtual: el espacio contemporáneo para compra y venta de bienes, productos y servicios.",
        link: "https://blog.workon.law/2023/11/13/metaverso-y-comercio-virtual-el-espacio-contemporaneo-para-compra-y-venta-de-bienes-productos-y-servicios/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/11/Metaverso-uai-258x172.jpg"
      },
      {
        date: "12/05/2023",
        text: "Abogados espaciales: Un futuro sin límites necesita a la ley",
        link: "https://blog.workon.law/2023/05/12/abogados-espaciales-un-futuro-sin-limites-necesita-a-la-ley/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/05/Espacio-y-abogados-scaled-uai-258x172.jpg"
      },
      {
        date: "17/03/2023",
        text: "Brecha Salarial: Una división por tradición",
        link: "https://blog.workon.law/2023/03/17/brecha-salarial-una-division-por-tradicion/",
        photo: "https://blog.workon.law/wp-content/uploads/2021/10/Foto-abogados-scaled-uai-258x172.jpg"
      },
      {
        date: "25/09/2023",
        text: "El complejo mundo de las siglas legales",
        link: "https://blog.workon.law/2023/09/25/el-complejo-mundo-de-las-siglas-legales/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/09/Siglas-uai-258x172.jpg"
      },
      {
        date: "20/09/2023",
        text: "La sostenibilidad de negocio de un estudio de abogados, ¿estás preparado?",
        link: "https://blog.workon.law/2023/09/20/la-sostenibilidad-de-negocio-de-un-estudio-de-abogados-estas-preparado/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/09/Economia-Abogados-scaled-uai-258x172.jpg "
      },
      {
        date: "04/08/2023",
        text: "Desde Egipto y Babilonia hasta Latinoamérica: el origen de los abogados",
        link: "https://blog.workon.law/2023/08/04/desde-egipto-y-babilonia-hasta-latinoamerica-el-origen-de-los-abogados/",
        photo: "https://blog.workon.law/wp-content/uploads/2023/08/Historia-de-Abogados-scaled-uai-258x172.jpg"
      }
    ],
  }
}

export default wolData;